import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "modal", "fixed", "backdrop" ]

  connect() {
    this.fixedTargets.forEach( (el) => {
      const $el = $(el);
      $(".new-modal-container").find(".new-modal__actions").hide()
    });
  }

  close(e) {
    if(!$(e.target).hasClass("new-modal-container") && !$(e.target).hasClass("new-modal__close") && !$(e.target).parents(".new-modal__close").length > 0) {
      return
    }
    $(".new-modal-container").fadeOut().addClass("d-none")
    $("body").removeClass("overflow-hidden")
    e.preventDefault()
  }

  open() {
    $(".new-modal-container").fadeIn().removeClass("d-none")
    $("body").addClass("overflow-hidden")
  }

  clickHandler(e) {
    e.preventDefault()
    e.stopImmediatePropagation()
    const $el = $(e.target)
    const href = $el.attr("href") || $el.parents("a").first().attr("href")
    this.loadRemoteModalContent(href)
  }

  toggleHandler(e) {
    this.open()
    this.loadRemoteModalContent(e.target.dataset.url)
  }

  setLoading() {
    let $modalContainer = $(".new-modal-container")
    $modalContainer.find(".new-modal__body").html("<div class='w-100 text-center pt-4'><div class='spinner-border spinner-border-sm'></div></div>")
    $modalContainer.find('.new-modal__title').html('')
    $modalContainer.find(".new-modal__footer").html('')
  }

  showError(message) {
    let $modalContainer = $(".new-modal-container")
    $modalContainer.find(".new-modal__body").html("<div class='error'>" + message + "</div>")
  }

  loadRemoteModalContent(url) {
    let $modalContainer = $(".new-modal-container")
    this.setLoading()

    let params =
        'partial=true&modal=1&redirect_to=' +
        encodeURIComponent(window.location.pathname)
    let partialUrl = url + (url.includes('?') ? '&' : '?') + params

    this.open()


    fetch(partialUrl)
      .catch(error => {
        this.showError("Ein Fehler ist aufgetreten.")
        console.log("modal error", error)
      })
      .then(response => response.text())
      .then(html => {
        let $html = $('<div/>').html(html)
        let $content = $html.find(".content").children()
        let $actions = $html.find(".actions, .modal-footer")
        let config = $html.find(".new-modal__config").data() || {}
        $modalContainer.find('.new-modal__title').html(config.title || "")
        $modalContainer.find('.new-modal__body').removeClass("p-0 p-1 p-2 p-3 p-4").addClass("p-" + config.padding || "4")
        $modalContainer.find(".new-modal__body").html( $content )
        $modalContainer.find(".new-modal__footer").html( $actions )
        $(document).trigger('remote-content-loaded')
      }).catch(error => {
        this.showError("Ein Fehler ist aufgetreten.")
        console.log("modal error", error)
      })
  }

  navigateToUrl(e) {
    window.Turbo.visit(e.target.dataset.url)
  }
}
