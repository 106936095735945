import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "input", "chats" ]

  filterList() {
      var value = $(this.inputTarget).val().toLowerCase();

      $(this.chatsTarget).children().filter(function() {
          let isVisible = $(this).text().toLowerCase().indexOf(value) > -1
          $(this).toggle(isVisible)
      })
  }
}
