const { DirectUpload } = require('@rails/activestorage')

class ImageFormField {
    static setup() {
        ImageFormField.uploadsInProgress = 0
        $('.ImageFormField__remove').on(
            'click',
            ImageFormField.removeImageFormField
        )
        $('.ImagesFormField__Items').sortable()
    }

    static handleImageFormFieldChange(el, e) {
        let input = e.currentTarget
        var $currentImageFormField = $(input).parents('.ImageFormField')

        if (input.files && input.files.length > 0) {
            if ($currentImageFormField.hasClass('ImageFormField--new')) {
                $newImageFormField = $currentImageFormField.clone()
                $newImageFormField.find('input[type=file]').val(null)
                $newImageFormField.insertAfter($currentImageFormField)
                $currentImageFormField.appendTo('.ImagesFormField__Items')
                $currentImageFormField
                    .removeClass('ImageFormField--new')
                    .removeClass('ImageFormField--empty')
            }

            for (var file of input.files) {
                if (file != input.files[0]) {
                    $currentImageFormField = $currentImageFormField
                        .clone()
                        .insertAfter($currentImageFormField)
                    $currentImageFormField
                        .find('.ImageFormField__remove')
                        .on('click', ImageFormField.removeImageFormField)
                }

                ImageFormField.processFile(file, $currentImageFormField)
            }
        }
        $('.ImagesFormField__Items').sortable('refresh')
    }

    static processFile(file, $imageFormField) {
        var reader = new FileReader()
        reader.onload = function (e) {
            $imageFormField.css(
                'background-image',
                'url(' + e.target.result + ')'
            )
        }
        reader.readAsDataURL(file)
        ImageFormField.uploadFile(file, $imageFormField)
    }

    static uploadFile(file, $imageFormField) {
        let $imageFormFieldStatus = $imageFormField.find(
            '.ImageFormField__Status'
        )
        let input = $imageFormField.find('input[type=file]')[0]
        ImageFormField.uploadsInProgress++

        $imageFormField.parents('form').attr('disabled', true)

        const url = input.dataset.directUploadUrl
        const upload = new DirectUpload(file, url, {
            directUploadWillStoreFileWithXHR(request) {
                request.upload.addEventListener('progress', (event) => {
                    $imageFormFieldStatus.text(
                        `Fortschritt: ${(
                            (event.loaded / event.total) *
                            100
                        ).toFixed()}%`
                    )
                })
            },
        })

        upload.create((error, blob) => {
            $(input).val(null)

            if (error) {
                ImageFormField.uploadsInProgress--
                $imageFormFieldStatus.text('Fehler: ' + error)
            } else {
                ImageFormField.uploadsInProgress--
                $imageFormFieldStatus.text('Hochgeladen!')
                $imageFormField
                    .find('.ImageFormField__BlobIdField')
                    .val(blob.signed_id)
            }

            if (ImageFormField.uploadsInProgress === 0) {
                $imageFormField.parents('form').attr('disabled', false)
            }
        })
    }

    static removeImageFormField(e) {
        e.preventDefault()
        e.stopImmediatePropagation()
        $(e.currentTarget).parents('.ImageFormField').remove()
    }
}

window.handleImageFormFieldChange = function (el, e) {
    ImageFormField.handleImageFormFieldChange(el, e)
}

window.updateImageFormFieldPreview = function (el, e) {
    let input = e.currentTarget
    let $imageFormField = $(input).parents('.ImageFormField')

    if (input.files && input.files[0]) {
        var reader = new FileReader()
        reader.onload = function (e) {
            $imageFormField
                .css('background-image', 'url(' + e.target.result + ')')
                .removeClass('ImageFormField--empty')
        }
        reader.readAsDataURL(input.files[0])
    }
}

$(document).ready(ImageFormField.setup)
$(document).on('remote-content-loaded', ImageFormField.setup)
