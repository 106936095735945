require('@rails/ujs').start()
const activeStorage = require('@rails/activestorage')
activeStorage.start()

const DirectUpload = activeStorage.DirectUpload

require('channels')
require('jquery.ui.touch_punch.js')
require('image_form_field.js')
require('paige.js')
require('turbo-scroll.js')
window.JitsiMeetExternalAPI = require('jitsi.js')
const { isTouch } = require('utils.js')
const vhCheck = require('vh-check')
window.Turbo = require('@hotwired/turbo')
Turbo.start()



const Trix = require('trix')
require('@rails/actiontext')
Trix.config.attachments.preview.caption.name = false
Trix.config.attachments.preview.caption.size = false
Trix.config.attachments.file.caption.name = false
Trix.config.attachments.file.caption.size = false

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
const application = Application.start()
const context = require.context("../controllers", true, /\.js$/)
application.load(definitionsFromContext(context))

window.setupApp = function () {
    let isChrome =
        /Chrome/.test(navigator.userAgent) &&
        /Google Inc/.test(navigator.vendor)

    if (isChrome) {
        $('body').addClass('chrome')
    }

    if (isTouch()) {
        $('body').addClass('touch')
    }

    $('.Popout').on('click', function (e) {
        $(e.currentTarget).addClass('Popout--open')
    })

    $('.JournalEntryModal .close').on('click', function (e) {
        let $btn = $(e.currentTarget)
        let $modal = $btn.parents('.JournalEntryModal')
        $modal.find('.modal-footer').show()
        $modal.find('.modal-body-view').show()
        $modal.find('.modal-body-edit').hide()

        $modal.hide()
        $('body').removeClass('modal-open')
        $('.modal-backdrop').fadeOut()
    })

    $(document).on('click', function (e) {
        if ($(e.target).parents('.Popout').length === 0) {
            $('.Popout--open').removeClass('Popout--open')
        }
    })



}

$(document).ready(function () {
    vhCheck()
    setupApp()
})
