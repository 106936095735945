import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "toggleLink" ]

  toggle(e) {
    let $filterSection = $(this.element)
    let $checkboxes = $filterSection.find('input[type=checkbox]')
    let newValue = !$checkboxes.first().attr('checked')
    $filterSection.find('input[type=checkbox]').attr('checked', newValue)
    $(this.toggleLinkTarget).text(newValue ? '(Keine)' : '(Alle)')

    e.preventDefault()
  }
}
