
(function (){
    //store positions
    var scrollPositions = {};

    //before turbo rerenders, safe all positions of permanent parts
    addEventListener("turbo:before-render", function () {
        document.querySelectorAll("[data-turbo-permanent]").forEach(function(element){
            scrollPositions[element.id] = element.scrollTop;
        });
    })

    //one it did render, retrieve positions and set accordingly
    addEventListener("turbo:render", function () {
        document.querySelectorAll("[data-turbo-permanent]").forEach(function(element){
            element.scrollTop = scrollPositions[element.id];
        });
    })
})()


