import { Controller } from "stimulus"
import { options } from "./date_picker_controller";

export default class extends Controller {
  connect(e) {
    let [startDatePicker, endDatePicker] = $(this.element).find('.js-date-picker')

    $(startDatePicker).datetimepicker(options)
    $(endDatePicker).datetimepicker(options)

    $(startDatePicker).on('change.datetimepicker', function (e) {
      let startDate = $(startDatePicker).datetimepicker('date')
      let endDate = $(endDatePicker).datetimepicker('date')
      $(endDatePicker).datetimepicker('minDate', e.date)
      if(endDate < startDate) {
        $(endDatePicker).datetimepicker('date', startDate)
      }
    })

    $(endDatePicker).on('change.datetimepicker', function (e) {
      $(startDatePicker).datetimepicker('maxDate', e.date)
    })
  }
}
