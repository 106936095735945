import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["email", "accountholder"]
  static values = {
    pkey: String,
    secret: String,
    subscribePath: String,
  }

  connect() {
    this.stripe = window.Stripe(this.pkeyValue);
    const elements = this.stripe.elements();
    const options = {
      supportedCountries: ['SEPA'],
      // Elements can use a placeholder as an example IBAN that reflects
      // the IBAN format of your customer's country. If you know your
      // customer's country, we recommend that you pass it to the Element as the
      // placeholderCountry.
      placeholderCountry: 'DE',
      style: {
        base: {
          width: 'auto',
          color: '#32325d',
          fontSize: '16px',
          '::placeholder': {
            color: '#aab7c4'
          },
          ':-webkit-autofill': {
            color: '#32325d',
          },
        },
        invalid: {
          color: '#fa755a',
          iconColor: '#fa755a',
          ':-webkit-autofill': {
            color: '#fa755a',
          },
        }
      }
    };
    this.iban = elements.create('iban', options)
    this.iban.mount('#iban-element');

    this._submitHandler = this.submitHandler.bind(this);
    this.element.addEventListener('submit', this._submitHandler);
  }

  disconnect() {
    this.element.removeEventListener('submit', this._submitHandler);
  }

  submitHandler(e) {
    e.preventDefault();
    this.element.querySelector('#submit-button').disabled = true;
    this.stripe.confirmSepaDebitSetup(
      this.secretValue,
      {
        payment_method: {
          sepa_debit: this.iban,
          billing_details: {
            name: this.accountholderTarget.value,
            email: this.emailTarget.value,
          },
        },
      }
    ).then(this.setupHandler());
  }

  setupHandler() {
    return (result) => {
      if(result.error) {
        console.log(result.error)
      } else {
        fetch(this.subscribePathValue, {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            payment_method: result.setupIntent.payment_method
          })
        }).then((response) => {
          window.location.href = response.url;
        }).catch((error) => {
          console.log("error creating subscription with SEPA payment metod");
        });
      }
    }
  }

}
