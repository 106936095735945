import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "body", "arrivalTime", "departureTime" ]
  static values = { "confirm": String }

  connect() {
    this.closeBtn = $(this.bodyTarget).parents('.modal').find('.close')
    this.handler = this.closeHandler.bind(this)
    this.closeBtn.on('click', this.handler)
  }

  disconnect() {
    this.closeBtn.off('click', this.handler)
  }

  closeHandler(e) {
    if(this.checkAttendanceTimeFieldForChange(this.arrivalTimeTarget) ||
       this.checkAttendanceTimeFieldForChange(this.departureTimeTarget) ) {

      if(confirm(this.confirmValue)) {
        this.reload()
      } else {
        e.stopImmediatePropagation()
      }

    } else {
      this.reload()
    }
  }

  reload() {
    window.Turbo.visit(window.location.href, { action: 'replace' })
  }

  checkAttendanceTimeFieldForChange(el) {
    const $el = $(el)
    const initialValue = $el.data("initialvalue") || ""
    const currentValue = $el.val()
    return currentValue !== initialValue
  }
}
