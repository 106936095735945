window.Paige = {
  session: null,
  registerFirebaseToken: (token) => {
    fetch('/me/firebase_registrations', {
      method: 'POST',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      redirect: 'error',
      body: JSON.stringify({ token: token })
    })
    .then(response => response.json())
    .then(data => {
      Paige.log("Paige.registerFirebaseToken " + JSON.stringify(data))
    })
  },

  refreshSession: (callback) => {
    fetch('/me/session')
      .then(response => response.json())
      .then(session => {
        if(callback) { callback(session) }
        Paige.updateSession(session)
      });
  },

  notifySessionObserver: () => {
    Paige.notifyNativeObserver("sessionObserver", Paige.session);
  },

  notifyNativeObserver: () => {
    if(window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.sessionObserver){
      window.webkit.messageHandlers.sessionObserver.postMessage(Paige.session);
    } else if(window.AndroidMessageObserver) {
      window.AndroidMessageObserver.postMessage("sessionObserver", JSON.stringify(Paige.session));
    }
  },

  updateSession: (session) => {
    console.log("Paige.session:", session)
    Paige.log("Paige Session " + JSON.stringify(session))
    Paige.session = session
    Paige.notifySessionObserver()
  },

  refreshCurrentPage: () => {
  },

  log: (message) => {
    if(window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.logObserver){
      window.webkit.messageHandlers.logObserver.postMessage(message);
    }
  },

  isNative: () => {
    return navigator.userAgent.includes("PAIGE")
  },

  updateNotice: (noticeText) => {
    let newNotice = $("<p class='notice'>").text(noticeText)
    $(".notice-container").html(newNotice)
  }
}

window.registerFirebaseToken = Paige.registerFirebaseToken
