import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "input", "chat", "file", "label", "textarea", "textLimit" ]

  filterList(e) {
    const value = $(this.inputTarget).val().toLowerCase();

    $(this.chatTargets).filter(function(el) {
      const $el = $(this)
      const isVisible = $el.text().toLowerCase().indexOf(value) > -1
      $el.toggle(isVisible)
    })
  }

  changeTextarea(e) {
    const $textarea = $(this.textareaTarget)
    const $textLimit = $(this.textLimitTarget)
    const textLength = $textarea.val().length
    $textLimit.text(textLength === 0 ? "" : `${textLength}/${$textarea.attr("maxlength")}`)

  }

  setFilename(e) {
    const $el = $(this.fileTarget)
    const $label = $(this.labelTarget)
    $label.toggleClass("Chat__Form__File__Label--with-file", $el.val())
    const splitted = $el.val().split("\\")
    const filename = splitted[splitted.length - 1]
    $(this.textLimitTarget).hide();
    $(this.textareaTarget).val("(Angehängte Datei)").prop("disabled", $el.val())
  }

  removeFile(e) {
    $(this.fileTarget).val("")
    $(this.labelTarget).removeClass("Chat__Form__File__Label--with-file")
    $(this.textareaTarget).val("").prop("disabled", false)
    $(this.textLimitTarget).show();
    e.stopImmediatePropagation()
    e.preventDefault()
  }
}
