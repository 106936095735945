import { Controller } from "stimulus"

export default class extends Controller {
    static targets = [ "scrollView" ]

    initialize() {
        this.didScroll = _.throttle(this.didScroll, 100)
    }

    connect() {
        let $button = $(this.element).find(".WeekCalendar-DaySelect.active")
        this.scrollToSelectedDay($button)
    }

    didSelectDay(e) {
        this.scrollToSelectedDay($(e.target))
        e.preventDefault()
    }

    scrollToSelectedDay($button) {
        $button.addClass('active').siblings().removeClass('active')
        let $dayPanel = $(this.element).find($button.attr("href"))
        let scrollOffset = $dayPanel[0].offsetLeft
        $(this.scrollViewTarget).scrollLeft(scrollOffset)
    }

    didScroll(e) {
        let currentScrolledDay = this.currentScrolledDay()
        if(currentScrolledDay === 0){
            window.location = $(this.element).find(".WeekCalendar-Day-placeholder:first-child").attr("href")
        } else if(currentScrolledDay === 6) {
            window.location = $(this.element).find(".WeekCalendar-Day-placeholder:last-child").attr("href")
        } else {
            this.selectDay(currentScrolledDay - 1)
        }
    }

    currentScrolledDay() {
        let elementWidth = $(this.scrollViewTarget).children()[0].clientWidth
        return Math.round(this.scrollViewTarget.scrollLeft / elementWidth)      
    }

    selectDay(i) {
        let $day = $($(this.element).find(".WeekCalendar-DaySelect")[i])
        $day.addClass('active').siblings().removeClass('active')
    }
}
