import { Controller } from "stimulus"

export default class extends Controller {
  connect(e) {
    const btn = $($(this.element).find('.js-questionnaire-scroll'));

    if(btn) {
      this.setupScroll(btn);
    }
  }

  setupScroll(btn) {
    btn.removeClass('questionnaire-scroll--hidden');

    const topBar = $('.js-header');
    const tableHead = $($(this.element).find('.js-questionnaire-table-head'))
    const collapsedSections = $($(this.element).find('.js-questionnaire-collapse'));
    const scrollOptions = true;

    btn.on('click', function(e) {
      e.preventDefault();
      const nextInput = $('input[type=radio]:not([disabled])').first();

      if(nextInput.parents('.js-questionnaire-collapse').hasClass('show')) {
        nextInput[0].scrollIntoView(scrollOptions);
      } else {
        collapsedSections.collapse('show');
        collapsedSections.one('shown.bs.collapse', function() {
          nextInput[0].scrollIntoView(scrollOptions);
        });
      }
    });
  }
}
