import { Controller } from "stimulus"

export default class extends Controller {
  static values = { confirm: String }

  connect() {
    this.handler = this.confirmHandler.bind(this);
    document.addEventListener('turbo:before-visit', this.handler);
    document.addEventListener('turbo:submit-start', this.removeHandler.bind(this));
  }

  disconnect() {
    this.removeHandler();
  }

  confirmHandler(e) {
    if(window.confirm(this.confirmValue)) {
      this.removeHandler()
    } else {
      e.preventDefault();
    }
  }

  removeHandler() {
    document.removeEventListener('turbo:before-visit', this.handler);
  }
}
