import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["button"]
  static values = {
    sessionsPath: String,
    setupPath: String
  }

  connect() {
    this.setupStripe();
  }

  disconnect() {
    if(this.checkoutHandler) {
      this.buttonTarget.removeEventListener('click', this.checkoutHandler)
    }
  }

  handleFetchResult(result) {
    if (!result.ok) {
      return result.json().then(function(json) {
        if (json.error && json.error.message) {
          throw new Error(result.url + ' ' + result.status + ' ' + json.error.message);
        }
      }).catch(function(err) {
        // FIXME show error message
        console.log(err);
        throw err;
      });
    }
    return result.json();
  }

  handleCheckoutErrors(result) {
    if (result.error) {
      // FIXME display error
      console.log(result.error.message);
    }
  }

  setupStripe() {
    fetch(this.setupPathValue)
      .then(this.handleFetchResult)
      .then((json) => {
        this.checkoutHandler = this.createCheckoutHandler(
          window.Stripe(json.publishableKey),
          json.priceId
        );
        this.buttonTarget.addEventListener("click", this.checkoutHandler);
      })
      .catch((error) => {
        console.log("error setting up stripe", error);
      });
  }

  createCheckoutSession(priceId) {
    return fetch(this.sessionsPathValue, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        priceId: priceId
      })
    }).then((result) => {
      return result.json();
    }).catch((error) => {
      console.log("error creating checkout session")
    });
  };

  createCheckoutHandler(stripe, priceId) {
    return (e) => {
      this.createCheckoutSession(priceId).then((data) => {
        stripe
          .redirectToCheckout({
            sessionId: data.sessionId
          })
          .then(this.handleCheckoutErrors);
      });
    }
  }

}
