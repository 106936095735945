import { Controller } from "stimulus"

export default class extends Controller {
  connect(e) {
    $(this.element).parents("form").on("submit", () => {
      let disableWith = $(this.element).data("disable-with")
      $(this.element).attr("disabled", true)
      if(disableWith) {
        $(this.element).html(disableWith)
      }      
    })
  }
}
