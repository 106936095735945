import { Controller } from "stimulus"

export default class extends Controller {
  static values = { isParent: Boolean, roomName: String, jwt: String }

  connect(e) {
    this.setupJitsi();
  }

  setupJitsi() {
    const api = new window.JitsiMeetExternalAPI("8x8.vc", {
      parentNode: this.element,
      roomName:   this.roomNameValue,
      jwt:        this.jwtValue,
      configOverwrite: {
        startWithAudioMuted: this.isParentValue
      },
      interfaceConfigOverwrite: {
        HIDE_INVITE_MORE_HEADER: true,
        TOOLBAR_BUTTONS: [
          'microphone', 'camera', 'fullscreen',
          'fodeviceselection', 'hangup', 'profile', 'chat',
          'settings', 'raisehand',
          'videoquality',
          'tileview', 'mute-everyone'
        ]
      }
    });
  }
}
